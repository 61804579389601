<template>
  <v-container>
    <v-skeleton-loader type="text" v-if="project == null" width="50%"/>
    <v-btn v-else small text :href="project.info.projectUrl" target="_blank"
      ><v-icon small left>mdi-open-in-new</v-icon
      >{{ project.info.projectUrl }}
    </v-btn
    >
  </v-container>
</template>

<script>
import projects from '../../services/projects'
export default {
  name: 'ProjectOverview',
  created() {
    projects.getProjectById(this.$route.params.projectId).then(res => {
      this.project = res
    })
  },
  data: () => ({
    project: null
  }),
}
</script>
